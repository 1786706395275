import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth/auth.service';
import { FileUploader } from 'ng2-file-upload';

export interface IConfig {
  type?: 'files';
  onlyView?: boolean; // Default: false
  max?: number; // Default: 1
  profile?: string; // Default: 'default'
  addMediasToFileComponent?: boolean;
}

import { FilesCDNModalComponent } from '@shared/components/modals/files-cdn-modal/files-cdn.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upload-files',
  templateUrl: 'upload-files.html',
  styleUrls: ['./upload-files.scss'],
})
export class UploadFilesComponent implements OnInit {
  @ViewChild('uploadFile', { read: ElementRef, static: false })
  public uploadFile: any;

  @Input() config: IConfig;
  @Input() files;
  @Input() info: boolean;
  @Input() xls?: boolean;
  @Input() csv?: boolean;
  @Input() autoUpload?: boolean = true;
  @Input() showRepository: boolean = true;
  @Input() respositoryExtensions: string[] = [];
  @Input() confirmOnDelete: boolean = true;
  @Output() updatePhotos = new EventEmitter();

  private max: number;
  public hideInput = false;
  public uploader: FileUploader;
  public fileInfoTypes: string;
  gallery: any = [];
  fileError: string;
  hasBaseDropZoneOver = false;
  endPointStatic: string;
  type: string;

  public bsModalRef?: BsModalRef;
  public confirmShown: boolean = false;
  public fileToBeDeleted: any;

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.endPointStatic = environment.static;
    this.max = this.config.max ? this.config.max : 1;
    this.type = 'files';
    this.gallery = this.files ? this.files : [];

    let allowedMimeType =
      this.config?.type === 'files' && !this.xls
        ? [
            'text/plain',
            'text/csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            '.pdf',
            'application/x-pdf',
            'application/pdf',
            'video/mp4',
            'audio/mpeg',
          ]
        : this.config?.type === 'files' && this.xls
        ? [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]
        : this.config?.type === 'files' && this.csv
        ? ['text/csv']
        : [
            'image/svg+xml',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/bmp',
            'image/x-windows-bmp',
            'image/gif',
          ];

    allowedMimeType = this.config.addMediasToFileComponent
      ? allowedMimeType.concat(['image/jpeg', 'image/png'])
      : allowedMimeType;

    this.fileInfoTypes = this.respositoryExtensions.length
      ? this.respositoryExtensions.join(', ')
      : this.config.addMediasToFileComponent
      ? '.pdf, .xls, .doc, .csv, .mp4, .mpeg'
      : this.xls
      ? '.xls, .xlsb, .xlsm, .xlsx'
      : '.pdf, .mp4, .mpeg';

    this.uploader = new FileUploader({
      url: `${environment.api}/${this.type}`,
      autoUpload: this.autoUpload,
      queueLimit: this.max,
      itemAlias: 'file',
      //allowedMimeType,
      additionalParameter: {
        profile: this.config.profile ? this.config.profile : 'default',
      },
      authToken: `Bearer ${this.authService.currentToken}`,
    });

    if (this.gallery.length === this.max) {
      this.hideInput = true;
    }

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.fileError = null;
      if (!this.autoUpload) {
        this.gallery.push(file);
        //this.uploader.clearQueue();
        this.reset();
        this.updateGallery();
      }
    };

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      this.fileError =
        'Ha ocurrido un error en la carga. Revise el tipo de archivo subido.';
      this.uploader.clearQueue();
      this.reset();
      this.updateGallery();
    };

    this.uploader.onErrorItem = (item, response, status, headers) => {
      console.log(item, status);
      const errorCases = {
        400: 'El nombre de archivo que está intentando utilizar ya existe en el repositorio. Por favor, elija otro nombre para su archivo.',
        default:
          'Ha ocurrido un error en la carga. Inténtelo de nuevo, por favor.',
      };

      this.uploader.clearQueue();
      this.reset();
      this.updateGallery();

      this.fileError = errorCases[status] ?? errorCases.default;
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.gallery.push(JSON.parse(response).data);
      this.uploader.clearQueue();
      this.reset();
      this.updateGallery();
    };
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public reset() {
    if (this.gallery?.length === this.max) {
      this.hideInput = true;
    }
    this.uploadFile.nativeElement.value = '';
  }

  public upload(event) {
    if (this.uploader.queue.length > 1) {
      this.uploader.queue[0].remove();
    }
  }

  public delete(fileDelete) {
    this.gallery.forEach((file, index) => {
      if (file?.hash === fileDelete?.hash) {
        this.gallery.splice(index, 1);
      }
    });
    if (this.gallery.length < this.max) {
      this.hideInput = false;
    }
  }

  public deleteFile(fileDelete) {
    if (this.confirmOnDelete && !this.confirmShown) {
      this.fileToBeDeleted = fileDelete;
      this.confirmShown = true;
      return;
    }

    this.uploader.clearQueue();
    this.delete(fileDelete);
    this.confirmShown = false;
  }

  public getImages() {
    return this.gallery;
  }

  public updateGallery() {
    this.updatePhotos.emit(this.gallery);
  }

  public showFileCDNModal(): void {
    this.bsModalRef = this.modalService.show(FilesCDNModalComponent, {
      class: 'file-cdn-modal__modal-container',
      initialState: {
        allowOnlyExtensions: this.respositoryExtensions,
      },
    });

    this.bsModalRef.onHide.subscribe(() => {
      const { finalFile } = this.bsModalRef.content;
      if (finalFile?.id) {
        this.gallery = [finalFile];
        this.uploader.clearQueue();
        this.reset();
        this.updateGallery();
      }
    });
  }
}
